/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// MUI components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Library to format the datetime
import moment from "moment-jalaali";

// Images
import noAvatar from "assets/images/no_avatar.jpeg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

export default function data({ drivers, onDocsClickHandler, onShowMapsClickHandler }) {
  // debugger;
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: drivers.length
      ? [
          { Header: "نام و نام خانوادگی", accessor: "name", width: "25%", align: "left" },
          { Header: "نوع ماشین", accessor: "carType", align: "left" },
          { Header: "وضعیت مدارک", accessor: "status", align: "center" },
          { Header: "تاریخ عضویت", accessor: "createdDate", align: "center" },
          { Header: "عملیات", accessor: "action", align: "center" },
        ]
      : [],

    rows: drivers.map((driver) => ({
      name: (
        <Author
          image={noAvatar}
          name={`${driver.firstName} ${driver.familyName}`}
          email={driver.phoneNumber}
        />
      ),
      carType: <Job title={driver.carType} description={driver.carColor} />,
      status: (
        <MDBox ml={-1}>
          {driver.carDetailsSet === 0 && (
            <MDBadge badgeContent="ثبت نشده" color="warning" variant="gradient" size="sm" />
          )}{" "}
          {driver.verified === 0 && (
            <MDBadge badgeContent="بررسی نشده" color="warning" variant="gradient" size="sm" />
          )}{" "}
          {driver.verified === 1 && (
            <MDBadge badgeContent="تایید شده" color="success" variant="gradient" size="sm" />
          )}{" "}
          {driver.verified === -1 && (
            <MDBadge badgeContent="رد شده" color="success" variant="gradient" size="sm" />
          )}
        </MDBox>
      ),
      createdDate: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(driver.createdDate).format("jYYYY/jM/jD")}
        </MDTypography>
      ),
      action: (
        <MDBox ml={-1}>
          <MDButton
            sx={{ m: 0.2 }}
            variant="gradient"
            color="info"
            size="small"
            disabled={driver.carDetailsSet === 0}
            endIcon={<Icon>folder_copy</Icon>}
            onClick={() => onDocsClickHandler(driver.id)}
          >
            مدارک
          </MDButton>

          <MDButton
            sx={{ m: 0.2 }}
            variant="gradient"
            color="success"
            size="small"
            endIcon={<Icon>remove_red_eye</Icon>}
            onClick={() => onShowMapsClickHandler(driver.id)}
          >
            پیمایش
          </MDButton>
        </MDBox>
      ),
    })),
  };
}
