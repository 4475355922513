// @mui material components
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";

// Custom MD component
import MDBox from "components/MDBox";

import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

function DocsModal({ style, open, onCloseHandler, driverId }) {
  const [docs, setDocs] = useState(null);
  const [lastDriverId, setLastDriverId] = useState(-1);

  const fetchDocs = useCallback(async () => {
    try {
      setLastDriverId(driverId);
      const res = await axios({
        url: `https://api.paniztaxico.ir/drivers-temp/docs/${driverId}`,
        method: "GET",
      });

      const result = res.data;
      if (result.status === 200) {
        setDocs(result.data);
      }
    } catch (err) {
      console.log("RDICH_ERR", err);
    }
  });
  useEffect(() => {
    if (open && driverId !== lastDriverId) {
      setDocs(null);
      fetchDocs();
    }
  }, [lastDriverId, open, driverId, fetchDocs]);
  return (
    <Modal open={open} onClose={onCloseHandler}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          height: "60%",
          bgcolor: "background.paper",
          p: 2,
          ...style,
        }}
      >
        {docs !== null && (
          <Grid container columnSpacing={2} rowSpacing={3}>
            {Object.keys(docs)
              .filter(
                (key) => key !== "id" && key !== "driver_id" && key !== "verified" && key !== "note"
              )
              .map(
                (key) =>
                  docs[key] !== null && (
                    <Grid item xs={4}>
                      <a
                        href={`https://paniztaxico.ir/${docs[key]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CardMedia
                          src={`https://paniztaxico.ir/${docs[key]}`}
                          component="img"
                          title="مدارک ارسالی راننده"
                          sx={{
                            maxWidth: "100%",
                            margin: 0,
                            boxShadow: ({ boxShadows: { md } }) => md,
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </a>
                    </Grid>
                  )
              )}
          </Grid>
        )}
      </MDBox>
    </Modal>
  );
}

// Setting default values for the props of DataTable
DocsModal.defaultProps = {
  style: undefined,
  open: true,
  onCloseHandler: () => {},
  driverId: undefined,
};

DocsModal.propTypes = {
  style: PropTypes.object || undefined,
  open: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  driverId: PropTypes.number || undefined,
};

export default DocsModal;
