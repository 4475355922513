/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import driversTableData from "layouts/drivers/data/driversTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

// Uses
import { useCallback, useEffect, useState } from "react";

// Network
import axios from "axios";

// modals import to use
import MapModal from "layouts/drivers/data/pointsMapModal";
import DocsModal from "layouts/drivers/data/docsModal";

function Drivers() {
  // const [drivers, setDrivers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDocs, setOpeningDocs] = useState(false);
  const [openMapModal, setOpeningMapModal] = useState(false);
  const [driverId, setDriverId] = useState(undefined);
  // const []

  const { columns: pColumns, rows: pRows } = projectsTableData();

  const onDocsClickHandler = (selectedDriverId) => {
    setDriverId(selectedDriverId);
    setOpeningDocs(true);
  };
  const onShowMapsClickHandler = (selectedDriverId) => {
    setDriverId(selectedDriverId);
    setOpeningMapModal(true);
  };

  const fetchDrivers = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios({
        url: "https://api.paniztaxico.ir/drivers-temp/list",
      });

      if (res.data.status === 200) {
        // setDrivers(res.data.data);
        const { columns: cols, rows: rws } = driversTableData({
          drivers: res.data.data,
          onDocsClickHandler,
          onShowMapsClickHandler,
        });

        console.log("TAG", cols, rws);
        setColumns(cols);
        setRows(rws);
      }
    } catch (err) {
      console.log(err);
    }
  });

  const onModalCloseHandle = () => setOpeningDocs(false);
  const onMapModalCloseHandle = () => setOpeningMapModal(false);

  // Changing the direction to rtl
  useEffect(() => {
    if (!loading) fetchDrivers();
  }, [loading, fetchDrivers]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  لیست رانندگان
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          {false && (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Projects Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>

      <MapModal open={openMapModal} onCloseHandler={onMapModalCloseHandle} driverId={driverId} />
      <DocsModal
        style={{ height: "70%", overflow: "scroll" }}
        open={openDocs}
        onCloseHandler={onModalCloseHandle}
        driverId={driverId}
      />
      {/* <DatePickerModal /> */}
      <Footer />
    </DashboardLayout>
  );
}

export default Drivers;
