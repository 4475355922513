// @mui material components
import Modal from "@mui/material/Modal";
import axios from "axios";

// Custom MD component
import MDBox from "components/MDBox";

import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

// ReactLeaflet maps
import { MapContainer, TileLayer } from "react-leaflet";

import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";

const createRoutineMachineLayer = (props) => {
  const instance = L.Routing.control({
    waypoints: props.points,
    lineOptions: {
      styles: [{ color: "#6FA1EC", weight: 4 }],
    },
    show: false,
    addWaypoints: false,
    routeWhileDragging: false,
    draggableWaypoints: false,
    fitSelectedRoutes: true,
    showAlternatives: false,
  });

  return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

function MapModal({ style, open, onCloseHandler, driverId }) {
  const [locations, setLocations] = useState([]);
  const [lastDriverId, setLastDriverId] = useState(-1);

  const fetchLocations = useCallback(async () => {
    try {
      setLastDriverId(driverId);
      const res = await axios({
        url: `https://api.paniztaxico.ir/drivers-temp/locations/${driverId}`,
        method: "GET",
      });

      const result = res.data;
      if (result.status === 200) {
        setLocations(result.data.map((loc) => L.latLng(loc.latitude, loc.longitude)));
      }
    } catch (err) {
      console.log("RDICH_ERR", err);
    }
  });
  useEffect(() => {
    if (open && driverId !== lastDriverId) {
      fetchLocations();
    }
  }, [lastDriverId, open, driverId, fetchLocations]);
  return (
    <Modal open={open} onClose={onCloseHandler}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          height: "60%",
          bgcolor: "background.paper",
          p: 2,
          ...style,
        }}
      >
        <MapContainer
          style={{ width: "100%", height: "100%" }}
          center={[35.7317778, 51.3764449]}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {locations.length && <RoutingMachine points={locations} />}
        </MapContainer>
      </MDBox>
    </Modal>
  );
}

// Setting default values for the props of DataTable
MapModal.defaultProps = {
  style: undefined,
  open: false,
  onCloseHandler: () => {},
  driverId: undefined,
};

MapModal.propTypes = {
  style: PropTypes.object || undefined,
  open: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  driverId: PropTypes.number || undefined,
};

export default MapModal;
